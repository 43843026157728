<script setup lang="ts">
const organizations = [
  {
    id: "0",
    label: "Indonesia Weekend Anthro Gathering",
    avatar: {
      src: "https://iwag.furries.id/images/logo/iwag-cloud.png",
    },
  },
  {
    id: "1",
    label: "Indonesia Furs",
    avatar: {
      src: "https://furries.id/images/logo.png",
    },
  },
  {
    id: "2",
    label: "New Organization",
  },
];

const organizationDropdownList = computed(() => [
  organizations.map((org) => {
    if (org.id === currentOrganization.value.id) {
      return {
        ...org,
        slot: "activeOrg",
      };
    } else {
      return {
        ...org,
        slot: "organization",
      };
    }
  }),
  [
    {
      label: "New organization",
      icon: "i-heroicons-plus",
    },
    {
      label: "Manage organizations",
      icon: "i-heroicons-cog-6-tooth",
    },
  ],
]);

const currentOrganization = ref(organizations[0]);
</script>

<template>
  <u-dropdown
    :items="organizationDropdownList"
    :popper="{ placement: 'bottom-start' }"
    :ui="{
      width: 'w-72',
    }"
  >
    <u-button
      color="gray"
      variant="solid"
      trailing-icon="i-heroicons-chevron-down-20-solid"
      class="w-full"
    >
      <template #leading>
        <u-avatar
          :alt="currentOrganization.label"
          :src="currentOrganization.avatar?.src"
          size="xs"
        />
      </template>
      <span class="truncate w-full text-start">{{
        currentOrganization.label
      }}</span>
    </u-button>

    <template #activeOrg="{ item }">
      <u-avatar :src="item.avatar?.src" size="2xs" :alt="item.label" />
      <span class="truncate w-full text-start">{{ item.label }}</span>
      <!-- <u-button size="2xs" variant="link" :padded="false" class="px-1"
        >Edit</u-button
      > -->
      <u-icon name="i-heroicons-check-20-solid" class="text-lg" />
    </template>

    <template #organization="{ item }">
      <u-avatar :src="item.avatar?.src" size="2xs" :alt="item.label" />
      <span class="truncate w-full text-start">{{ item.label }}</span>
    </template>
  </u-dropdown>
</template>
