<script setup lang="ts">
import type { DropdownItem } from "#ui/types";
const { t, locale } = useI18n();
const localePath = useLocalePath();
const { user: currentUser, isLoading } = useUser(true);

const userFullName = computed(() => {
  if (isLoading.value) return undefined;
  if (!currentUser?.value?.first_name) return undefined;

  return `${currentUser?.value?.first_name} ${currentUser?.value?.last_name}`;
});

const items = ref<DropdownItem[][]>([]);

const setItems = () => {
  items.value = [
    [
      {
        label: userFullName,
        slot: "account",
        disabled: true,
      },
      {
        label: t("my_account"),
        icon: "i-heroicons-user-circle",
        to: localePath("/account"),
      },
    ],
    [
      {
        label: t("sign_out"),
        icon: "i-heroicons-arrow-right-on-rectangle",
        click: () => window.location.assign("/api/auth/logout"),
      },
    ],
  ] as DropdownItem[][];
};

watch(locale, setItems);

setItems();
</script>

<template>
  <u-dropdown :items="items">
    <u-button
      variant="ghost"
      class="w-full"
      color="white"
      trailing-icon="i-heroicons-ellipsis-horizontal"
    >
      <div class="flex gap-2 items-center w-full">
        <u-avatar
          :src="currentUser?.avatar_url"
          :alt="userFullName"
          size="xs"
        />
        <span class="text-sm truncate w-full text-start">
          {{ userFullName }}
        </span>
      </div>
    </u-button>

    <template #account="{ item }">
      <div class="text-left">
        <p>Signed in as</p>
        <u-skeleton v-if="isLoading" class="h-5 w-16" />
        <p v-else class="truncate font-medium text-gray-900 dark:text-white">
          {{ item.label }}
        </p>
      </div>
    </template>
  </u-dropdown>
</template>
